import { Typography, Button, Tabs, Tab, Stack } from "@mui/material"
import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"

import Box from "@mui/material/Box"
import { useForm, FormProvider } from "react-hook-form"
import { getPrompt, savePrompt } from "../api/PromptService"
import { useSnackbar } from "notistack"
import TextFieldControlled from "../components/TextFieldControlled"

export const PROMPT = {
  PECSRR_POST_WITH_TONE: "PECSRR_POST_WITH_TONE",
  PECSRR_TITLES: "PECSRR_TITLES",
  PECSRR_COMMENTS: "PECSRR_COMMENTS",
  PECSRR_REPLIES: "PECSRR_REPLIES",
  PECSRR_HASHTAGS: "PECSRR_HASHTAGS",
  LI_POST_REPLIES: "LI_POST_REPLIES",
  LI_POST_HASHTAGS: "LI_POST_HASHTAGS",
  CLIENT_TONE: "CLIENT_TONE",
  SUMMARY: "SUMMARY",
}

const PromptTitle = {
  PECSRR_POST_WITH_TONE: "PECSRR Post With Tone",
  PECSRR_TITLES: "PECSRR Titles",
  PECSRR_COMMENTS: "PECSRR Comments",
  PECSRR_REPLIES: "PECSRR Replies",
  PECSRR_HASHTAGS: "PECSRR Hashtags",
  LI_POST_REPLIES: "LI Post Replies",
  LI_POST_HASHTAGS: "LI Post Hashtags",
  CLIENT_TONE: "Client Tone of Voice",
  SUMMARY: "Post Summary",
}

const PromptForm = ({ name }) => {
  const form = useForm({ defaultValues: { name } })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    ;(async () => {
      const { data } = await getPrompt(name)
      form.reset(data)
    })()
  }, [])

  const onSubmit = async (data) => {
    await savePrompt(data)
    enqueueSnackbar("Prompt saved!")
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <TextFieldControlled
          fullWidth
          multiline
          name="prompt"
          // inputProps={{ sx: { fontFamily: "Monospace" } }}
        />
        <Button mt={2} sx={{ float: "right", mt: 2 }} variant="contained" type="submit">
          Save prompt
        </Button>
      </form>
    </FormProvider>
  )
}

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={{ flexGrow: 1, px: 4 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function Prompts(props) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Typography variant="h5">All Prompts</Typography>
      <Stack direction="row" p={2}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {Object.keys(PROMPT).map((prompt, index) => (
            <StyledTab label={PromptTitle[prompt]} {...a11yProps(index + 1)} />
          ))}
        </Tabs>
        {Object.keys(PROMPT).map((prompt, index) => (
          <TabPanel value={value} index={index}>
            <PromptForm name={prompt} />
          </TabPanel>
        ))}
      </Stack>
    </>
  )
}

export default Prompts
